/**
 * Implement Gatsby's Browser APIs in this file.
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

/**
 * A few includes that are only important for the browser (not during builds).
 */
//import $ from 'jquery' // npm install jquery // https://www.npmjs.com/package/jquery
import 'lightbox2' // npm install lightbox2 // Must be in gatsby-browser.js otherwise build fails (another solution - see https://www.gatsbyjs.org/docs/debugging-html-builds/#how-to-check-if-window-is-defined)
import 'lightbox2/dist/css/lightbox.css' // npm install lightbox2 // CSS import must be in gatsby-browser.js

// https://www.gatsbyjs.com/docs/how-to/styling/other-css-frameworks/#bootstrap
// https://stackoverflow.com/questions/56493502/how-to-add-bootstrap-js-in-a-gatsby-website/59746010#59746010
import 'bootstrap/dist/css/bootstrap.min.css' // npm install bootstrap
import 'bootstrap/dist/js/bootstrap.min' // npm install bootstrap
// bootstrap.bundle.min.js ??? https://www.npmjs.com/package/bootstrap Bundled JS files (bootstrap.bundle.js and minified bootstrap.bundle.min.js) include Popper.
// https://getbootstrap.com/docs/5.0/getting-started/introduction/ Both bootstrap.bundle.js and bootstrap.bundle.min.js include Popper for our tooltips and popovers.
// import '@popperjs/core/dist/umd/popper.min' // npm install @popperjs/core
// 20221125 Found out that we must npm install @popperjs/core (otherwise the site does not build (only develop)) but that popper does NOT have to be imported in gatsby-browser.js
import 'bootstrap-icons/font/bootstrap-icons.css' // https://icons.getbootstrap.com/
//import 'masonry-layout/dist/masonry.pkgd.min.js'
//import Masonry3 from '/src/components/Masonry2'
//require('masonry-layout') // https://masonry.desandro.com/extras.html#module-loaders

/**
 * https://auth0.com/blog/securing-gatsby-with-auth0/
 */
/*
import React from 'react'
import { silentAuth } from './src/utils/auth'
class SessionCheck extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }
  }
  handleCheckSession = () => {
    this.setState({ loading: false })
  }
  componentDidMount() {
    silentAuth(this.handleCheckSession)
  }
  render() {
    return (
      this.state.loading === false && (
        <React.Fragment>{this.props.children}</React.Fragment>
      )
    )
  }
}
export const wrapRootElement = ({ element }) => {
  return <SessionCheck>{element}</SessionCheck>
}
*/

/**
 * Show the #back-to-top button only after scrolling down.
 */

// On every click in Gatsby (route update) make sure to immediatelly hide the button (it's at position zero then). Otherwise when you come to a page the button shows (is not hidden)!
// https://www.gatsbyjs.org/docs/browser-apis/#onRouteUpdate
// https://github.com/gatsbyjs/gatsby/pull/9239
export const onRouteUpdate = ({ location, prevLocation }) => {
  //console.log('new pathname', location.pathname)
  //console.log('old pathname', prevLocation ? prevLocation.pathname : null)
  //$('#back-to-top').hide() // .fadeOut()
  document.getElementById('back-to-top').style.visibility = 'hidden'
  //if (location.pathname === '/') {
  // or path to whatever page has cubeportfolio
  //$('#grid-container').cubeportfolio({...}); // pass needed options
  //console.log('onRouteUpdate!')
  /*
    if (typeof window !== 'undefined') {
      var msnry = new Masonry('.msnry', {
        itemSelector: '.col-1',
        percentPosition: true,
      })
      alert('hoi!')
    }
    */
  /*
    window.onload = function () {
      var msnry = new Masonry('.msnry', {
        itemSelector: '.col-1',
        percentPosition: true,
      })
      alert('hoi!')
    }
*/
  //Masonry3()
  /*
    $(document).ready(function () {
      $('.msnry').masonry({
        // options
        itemSelector: '.col-1',
        percentPosition: true,
      })
    })
    */
  /*
    $('.msnry').masonry({
      // options
      itemSelector: '.col-1',
      percentPosition: true,
    })
    */
  //}

  /*
  typeof window !== 'undefined'
    ? ''
    : new Masonry('.msnry', {
        itemSelector: '.col-1',
        percentPosition: true,
      })
  */
  /*
  var msnry = new Masonry('.msnry', {
    itemSelector: '.col-1',
    percentPosition: true,
  })
  */
}

// Show #back-to-top after we start scrolling down. Hide when we scroll back up.
/*
$(window).scroll(function () {
  if ($(this).scrollTop() > 10) {
    $('#back-to-top').fadeIn()
  } else {
    $('#back-to-top').fadeOut()
  }
})
*/
// https://isotropic.co/how-to-show-an-element-after-scrolling-halfway-down-the-page-js/
var myScrollFunc = function () {
  const btt = document.getElementById('back-to-top')
  var y = window.scrollY
  if (y >= 33) {
    //console.log('is more: ' + y)
    btt.style.visibility = '' // https://bobbyhadz.com/blog/javascript-hide-element-by-id
  } else {
    //console.log('is less: ' + y)
    btt.style.visibility = 'hidden' // https://bobbyhadz.com/blog/javascript-hide-element-by-id
  }
}
window.addEventListener('scroll', myScrollFunc)

/**
 * Render tweets from tweet URLs
 * https://developer.twitter.com/en/docs/twitter-for-websites/embedded-tweets/guides/embedded-tweet-javascript-factory-function
 * https://www.labnol.org/code/19933-embed-tweet-with-javascript
 * -> http://jsfiddle.net/jmcglone/5r25oy2d/
 */
/*
 var tweets = $('.tweet')

$(tweets).each(function(t, tweet) {
  var id = $(this).attr('id')

  twttr.widgets.createTweet(id, tweet, {
    conversation: 'none', // or all
    cards: 'hidden', // or visible
    linkColor: '#cc0000', // default is blue
    theme: 'light', // or dark
  })
})
*/

// https://stackoverflow.com/questions/54468240/how-to-include-jquery-in-a-gatsby-js-project/60615017#60615017
// import $ from 'jquery' // npm install jquery // https://www.npmjs.com/package/jquery
// Proving that jQuery (imported above) works:
// $(document).ready(function () {
//   console.log("The answer is don't think about it!")
// });
// Inspired by: https://css-tricks.com/filtering-data-client-side-comparing-css-jquery-and-react/
// also see: https://www.w3schools.com/howto/howto_js_filter_lists.asp
/*
export const onInitialClientRender = () => {
  $(document).ready(function () {
    console.log('jQuery ✓')

    const listItems = $('div.resultrow')

    $('#resultfilter').on('input', function () {
      let filter = $(this).val()
      if (filter) {
        listItems.hide()
        $('div.mapandmessages').hide() // If there is a filtering query, hide the map and also hide the texts about running on the running page.
        $('div.algoliasearchresults').hide() // Also hide the Algolia search results because their popup kept opening when I did a result filter search... a clumsy temporary solution.
        $(`div:contains('${filter}')`).show()
      } else {
        listItems.show()
      }
    })

    // case insensitive
    $.expr.pseudos.contains = function (a, i, m) {
      return $(a).text().toUpperCase().indexOf(m[3].toUpperCase()) >= 0
    }
  })
}
*/
